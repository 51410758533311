/** @jsxImportSource theme-ui */
import { Fragment } from "react";
import SectionPresentation from "./SectionPresentation";
import SectionAbout from "./SectionAbout";

const PageHome = () => {
  return (
    <Fragment>
      <SectionPresentation />
      <SectionAbout />
    </Fragment>
  );
};

export default PageHome;
