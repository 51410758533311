/** @jsxImportSource theme-ui */
import { useRef } from "react";
import { useIntersection } from "react-use";
import { useSpring, animated as a } from "react-spring";
import { transitionTimes } from "../utils/transitions";

const WaveEmoji = () => {
  // Intersection Observer
  const ref = useRef(null);
  const threshold = 1;
  const intersection = useIntersection(ref, { threshold });
  const inView = intersection ? intersection.intersectionRatio === threshold : false;

  // Spring
  const { x } = useSpring({
    x: inView ? 1 : 0,
    delay: inView ? transitionTimes.xl : 0,
    config: { duration: transitionTimes.md },
    ease: "ease-in",
  });

  return (
    <a.span
      ref={ref}
      style={{
        transform: x
          ? x
              .to({
                range: [0, 0.2, 0.4, 0.6, 0.8, 1],
                output: [0, -10, 12, -10, 9, 0],
              })
              .to((x) => `rotate(${x}deg)`)
          : "none",
      }}
      sx={{
        display: "inline-block",
        fontSize: ["lg", null, "xl", null, "xxl"],
        transformOrigin: "70% 70%",
        willChange: "transform",
      }}
    >
      👋
    </a.span>
  );
};

export default WaveEmoji;
