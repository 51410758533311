/** @jsxImportSource theme-ui */
import { useEffect, useRef } from "react";
import { useIntersection } from "react-use";
import { useSpring, animated as a, config } from "react-spring";
import Emphasis from "./Emphasis";
import Section from "./Section";
import WaveEmoji from "./WaveEmoji";
import { hiID } from "../utils/helpers";
import { transitionTimes } from "../utils/transitions";
import { routes } from "../utils/routes";

const SectionPresentation = () => {
  // Intersection Observer: Section
  const sectionRef = useRef(null);
  const threshold = 0.5;
  const intersection = useIntersection(sectionRef, { threshold });
  const inView = intersection ? intersection.intersectionRatio >= threshold : false;

  // When the whole section is visible, change the hash
  useEffect(() => {
    if (inView) history.pushState(null, null, routes.home);
  }, [inView]);

  // Spring creation: Content
  const { opacity, transform } = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? `translateY(0)` : `translateY(2vh)`,
    config: { ...config.slow, clamp: true },
    delay: inView ? transitionTimes.xs : 0,
  });

  return (
    <Section id={hiID} ref={sectionRef} sx={{ minHeight: 350 }}>
      <a.h1
        style={{ opacity, transform }}
        sx={{
          fontSize: ["xl", null, "xxl", null, "xxxxl"],
          fontWeight: "extraBold",
          lineHeight: "sm",
          pl: [2, 5, null, 6],
          pr: [2, 5],
          willChange: "opacity, transform",
        }}
      >
        <WaveEmoji />
        <br />
        I'm Jeremy Grancher,
        <br />
        full-stack&nbsp;
        <Emphasis
          sx={{
            fontFamily: "monospace",
            overflowWrap: "anywhere",
          }}
        >
          web_developer
        </Emphasis>
        .
      </a.h1>
    </Section>
  );
};

export default SectionPresentation;
