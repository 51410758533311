/** @jsxImportSource theme-ui */
import { useEffect, useRef } from "react";
import { Flex } from "theme-ui";
import { useIntersection } from "react-use";
import { useSpring, animated as a, config } from "react-spring";
import Copy from "./Copy";
import H2 from "./H2";
import Section from "./Section";
import SocialLinks from "./SocialLinks";
import { aboutID } from "../utils/helpers";
import { transitionTimes } from "../utils/transitions";
import { routes } from "../utils/routes";
import { about } from "../data";

const SectionAbout = () => {
  // Intersection Observer: Section
  const sectionRef = useRef(null);
  const threshold = 0.5;
  const intersection = useIntersection(sectionRef, { threshold });
  const inView = intersection ? intersection.intersectionRatio >= threshold : false;

  // When the whole section is visible, change the hash
  useEffect(() => {
    if (inView) history.pushState(null, null, routes.about);
  }, [inView]);

  // Spring creation: Title
  const AnimatedHeading = a(H2);
  const propsHeading = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? `translateY(0)` : `translateY(3vh)`,
    config: { ...config.slow, clamp: true },
    delay: inView ? transitionTimes.xs : 0,
  });

  // Spring creation: Copy
  const AnimatedCopy = a(Copy);
  const propsCopy = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? `translateY(0)` : `translateY(2vh)`,
    config: { ...config.slow, clamp: true },
    delay: inView ? transitionTimes.sm : 0,
  });

  return (
    <Section id={aboutID} ref={sectionRef} sx={{ overflow: "hidden" }}>
      <Flex
        as="article"
        sx={{
          flexDirection: "column",
          justifyContent: "center",
          pl: [2, null, 5, null, 6],
          pr: [2, null, 5],
          pt: (theme) => [
            Number(theme.sizes[4]) + Number(theme.space[2]) * 2,
            null,
            Number(theme.sizes[4]) + Number(theme.space[3]) * 2,
          ],
        }}
      >
        <AnimatedHeading
          style={propsHeading}
          sx={{
            mb: [2, 3],
            display: "none",
            willChange: "opacity, transform",
            "@media screen and (min-height: 455px)": {
              display: "block",
            },
          }}
        >
          {about.title}
        </AnimatedHeading>
        <AnimatedCopy
          style={propsCopy}
          sx={{
            maxWidth: "65ch",
            mb: 2,
            willChange: "opacity, transform",
          }}
        >
          {about.paragraph1}
        </AnimatedCopy>
        <AnimatedCopy
          style={propsCopy}
          sx={{
            maxWidth: "65ch",
            mb: 2,
            willChange: "opacity, transform",
          }}
        >
          {about.paragraph2}
        </AnimatedCopy>
        <AnimatedCopy
          style={propsCopy}
          sx={{
            maxWidth: "65ch",
            mb: 3,
            willChange: "opacity, transform",
            "@media screen and (min-height: 455px)": {
              mb: 5,
            },
          }}
        >
          {about.cta}
        </AnimatedCopy>
        <SocialLinks sx={{ mb: 2 }} />
      </Flex>
    </Section>
  );
};

export default SectionAbout;
